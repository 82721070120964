#videobackground .gradient {
  background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.69) 100%);
  background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.69) 100%);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.69) 100%);
}

#videobackground .view video {
  z-index: -100;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: 1s opacity;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

#videobackground .view {

  min-height: 100vh;
}